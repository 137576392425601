import React from 'react';
import css from './ListingPage.module.css';
import { AvatarLarge, Icons, NamedLink, PrimaryButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { UserFieldConfigKey, UserType } from '../../util/enums';
import { getLabelsByOptions, isArrayLength, truncateText } from '../../util/genericHelpers';
import moment from 'moment';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { getUserType } from '../../util/userDataExtraction';
import { useDispatch, useSelector } from 'react-redux';
import { hireDesignerSelector, initiateHireDesigner } from './ListingPage.duck';

function RenderDesignerInfo(props) {
  const {
    currentListing,
    currentAuthor,
    isOwnListing,
    isAuthenticated,
    setInquiryModalOpen,
    pathParams,
    currentUser,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { hireDesignerInProgress } = useSelector(hireDesignerSelector);
  const routeConfiguration = useRouteConfiguration();
  const { title = '', publicData = {}, createdAt } = currentListing?.attributes || {};
  const config = useConfiguration();
  const userFieldConfig = config.user.userFields;
  const educationAndAffiliations = userFieldConfig?.find(
    config => config?.key === UserFieldConfigKey.CERTACCRED
  );
  const marketSegmentSpecialization = userFieldConfig?.find(
    config => config?.key === UserFieldConfigKey.MSS
  );
  const manufacturersDetails = userFieldConfig?.find(
    config => config?.key === UserFieldConfigKey.MANUFACTURERS
  );

  const {
    manufacturers = [],
    certaccred = [],
    mss = [],
    currentLocation = '',
    designstyle = '',
    additionalmanufacturers = '',
    designdegreefreetext = '',
    yearsofexperience = '',
  } = publicData || {};
  const authorProfileCreatedAt = currentAuthor?.attributes?.createdAt;
  const matchedEducationAndAffiliations = getLabelsByOptions(educationAndAffiliations, certaccred);
  const matchedMarketSegmentSpecialization = getLabelsByOptions(marketSegmentSpecialization, mss);
  const matchedManufacturers = getLabelsByOptions(manufacturersDetails, manufacturers);
  const authorType = getUserType(currentAuthor);
  const isDesigner = authorType === UserType.DESIGNER_USER;
  const authorProfileCreatedDate = moment(authorProfileCreatedAt);
  const currentDate = moment();

  // Calculate years since the profile was created
  const yearsSinceProfileCreated = currentDate.diff(authorProfileCreatedDate, 'years');
  const updatedYearsOfExperience = parseInt(yearsofexperience, 10) + yearsSinceProfileCreated;
  const handleAuthOrRedirect = async () => {
    if (isAuthenticated) {
      // setInquiryModalOpen(true);
      const transaction = await dispatch(
        initiateHireDesigner({
          currentListing,
          currentUserId: currentUser?.id?.uuid,
        })
      );
      history.push(
        createResourceLocatorString(
          'OrderDetailsPage',
          routeConfiguration,
          { id: transaction?.id?.uuid },
          {}
        )
      );
    } else {
      history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}), {
        from: history?.location?.pathname,
      });
    }
  };

  return (
    <div className={css.contentWrapperForProductLayout}>
      {/* {isCompany ? renderJobDetails : renderListingDetails} */}
      <div className={css.breadCrumbSec}>
        <div className={css.leftSec}>
          <div className={css.breadcumbList}>
            <NamedLink name="LandingPage">
              <FormattedMessage id="ListingPage.homePageLink" />
            </NamedLink>
            <NamedLink name="SearchPage">
              <FormattedMessage id="ListingPage.searchPageLink" />
            </NamedLink>
            <NamedLink name="LandingPage" className={css.pointerEvents}>
              {currentListing?.attributes?.title}
            </NamedLink>
          </div>
        </div>
      </div>
      <div className={classNames(css.bannerSection, css.designerInfoSection)}>
        <div className={css.userDetails}>
          <h2>{designstyle}</h2>
          <div className={css.userCard}>
            <AvatarLarge
              className={css.avatar}
              user={currentAuthor}
              isDesigner={isDesigner}
              pathParams={pathParams}
            />
            {/* <span className={css.activeUser}></span> */}
            <div className={css.userInfo}>
              <h3>{title}</h3>
              <p>{publicData?.marketType} </p>
              <div className={css.otherDetails}>
                {currentLocation?.address ? (
                  <span>
                    <Icons name="location" /> {truncateText(currentLocation?.address)}
                  </span>
                ) : null}
                <span>
                  <Icons name="calendarCheck" /> <FormattedMessage id="ListingPage.membersince" />:{' '}
                  {createdAt && moment(createdAt).format('MMMM D, YYYY')}
                </span>
                <span>
                  <Icons name="calendarCheck" />{' '}
                  <FormattedMessage id="ListingPage.yearsOfExperience" />:{updatedYearsOfExperience}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.mainContentOuterWrapper}>
        <div className={css.mainContent}>
          <div className={css.otherDetails}>
            <h2>
              <FormattedMessage id="ListingPage.manufacturersHeading" />
            </h2>
            {isArrayLength(matchedManufacturers) ? (
              matchedManufacturers?.map((manufacturer, index) => (
                <ul key={index}>
                  <li>
                    <Icons name="check" /> {manufacturer}
                  </li>
                </ul>
              ))
            ) : (
              <p>
                <FormattedMessage id="ListingPage.noDataAvailable" />
              </p>
            )}
          </div>
          <div className={css.otherDetails}>
            <h2>
              <FormattedMessage id="ListingPage.educationAccreditationsAffiliations" />
            </h2>
            {isArrayLength(matchedEducationAndAffiliations) ? (
              matchedEducationAndAffiliations?.map((education, index) => (
                <ul key={index} className={css.bullets}>
                  <li>{education}</li>
                </ul>
              ))
            ) : (
              <p>
                <FormattedMessage id="ListingPage.noDataAvailable" />
              </p>
            )}
          </div>
          <div className={css.otherDetails}>
            <h2>
              <FormattedMessage id="ListingPage.marketSegmentSpecialization" />
            </h2>
            {isArrayLength(matchedMarketSegmentSpecialization) ? (
              matchedMarketSegmentSpecialization?.map((segment, index) => (
                <ul key={index}>
                  <li>
                    <Icons name="check" /> {segment}
                  </li>
                </ul>
              ))
            ) : (
              <p>
                <FormattedMessage id="ListingPage.noDataAvailable" />
              </p>
            )}
          </div>
          <div className={css.otherDetails}>
            <h2>
              <FormattedMessage id="ListingPage.designstyle" />
            </h2>
            <p>{designstyle && designstyle}</p>
          </div>
          <div className={css.otherDetails}>
            <h2>
              <FormattedMessage id="ListingPage.additionalmanufacturers" />
            </h2>
            <p>{additionalmanufacturers && additionalmanufacturers}</p>
          </div>
        </div>
        <div className={css.sidePanel}>
          <div className={css.otherDetails}>
            <div className={css.info}>
              <span>
                <Icons name="location" /> <FormattedMessage id="ListingPage.locationHeading" />
              </span>
              <span>{truncateText(currentLocation?.address)}</span>
            </div>

            <div className={css.info}>
              <span>
                <Icons name="calendarDate" /> <FormattedMessage id="ListingPage.membersince" />{' '}
              </span>
              <span> {createdAt && moment(createdAt).format('MMMM D, YYYY')}</span>
            </div>

            <div className={css.info}>
              <span>
                <Icons name="calendarCheck" /> <FormattedMessage id="ListingPage.lastDelivery" />
              </span>
              <span>
                <FormattedMessage id="ListingPage.lastDeliveryDate" />
              </span>
            </div>

            <div className={css.info}>
              <span>
                <Icons name="language" /> <FormattedMessage id="ListingPage.languages" />
              </span>
              <span>
                <FormattedMessage id="ListingPage.languageKnown" values={{ language: 'English' }} />
              </span>
            </div>
          </div>
          {!isOwnListing && (
            <div className={css.applySec}>
              <PrimaryButton
                type="button"
                onClick={handleAuthOrRedirect}
                inProgress={hireDesignerInProgress}
              >
                <FormattedMessage id="ListingPage.contactNow" />
                <Icons name="arrowUpRight" />
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RenderDesignerInfo;
