import React from 'react';
import css from './ListingPage.module.css';
import {
  AvatarLarge,
  AvatarMedium,
  Icons,
  IconSpinner,
  NamedLink,
  PrimaryButton,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { capitalizeFirstLetter, isArrayLength } from '../../util/genericHelpers';
import { designerLevels } from '../EditListingPage/EditListingWizard/EditListingJobPanel/ExperienceSection';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import moment from 'moment';
import {
  getBudgetType,
  getDesignerLevel,
  getJobCategory,
  getKeyResponsibilities,
  getListingDetails,
  getWorkExperience,
} from '../../util/listingDataExtractor';
import { getAuthorName } from '../../util/editListingHelpers';
import { createSlug } from '../../util/urlHelpers';
import { getUserType } from '../../util/userDataExtraction';
import { UserType } from '../../util/enums';

const renderPriceDetails = publicData => {
  const formatPrice = price => `$${(price / 100).toFixed(2)}`;

  if (publicData?.priceFrom) {
    return `${formatPrice(publicData.priceFrom)} - ${formatPrice(publicData.priceTo)}`;
  } else if (publicData?.fixedPrice) {
    return formatPrice(publicData.fixedPrice);
  } else {
    return null; // or any default value you want to return if no price is available
  }
};

const getPostedDaysAgo = date => {
  if (!date) return '';
  return moment(date).fromNow();
};

function RenderJobInfo(props) {
  const {
    currentListing,
    currentAuthor,
    isOwnListing,
    isAuthenticated,
    setInquiryModalOpen,
    history,
    filteredJobListings,
    pathParams,
    acceptedContractTransaction,
    fetchTransactionsInProgress,
    currentUser,
  } = props;
  const { title = '', description = '', publicData = {} } = currentListing?.attributes || {};
  const contractDetails = publicData?.contractDetails || {};
  const { customerId, providerId, projectUnderContract } = contractDetails || {};
  const createdAt = currentListing?.attributes?.createdAt;

  const postedDaysAgo = getPostedDaysAgo(createdAt);
  const workExperience = getWorkExperience(currentListing);
  const keyResponsibilities = getKeyResponsibilities(currentListing);
  const authorType = getUserType(currentAuthor);
  const isDesigner = authorType === UserType.DESIGNER_USER;

  const handleAuthOrRedirect = () => {
    if (isAuthenticated) {
      setInquiryModalOpen(true);
    } else {
      history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}), {
        from: history?.location?.pathname,
      });
    }
  };
  const userId = currentUser?.id?.uuid;
  const shouldRenderApplyButton = userId === customerId || userId === providerId;
  return (
    <div className={css.contentWrapperForProductLayout}>
      {/* {isCompany ? renderJobDetails : renderListingDetails} */}
      <div className={css.breadCrumbSec}>
        <div className={css.leftSec}>
          <div className={css.breadcumbList}>
            <NamedLink name="LandingPage">
              <FormattedMessage id="ListingPage.homePageLink" />
            </NamedLink>
            <NamedLink name="SearchPage">
              <FormattedMessage id="ListingPage.searchPageLink" />
            </NamedLink>
            <NamedLink name="LandingPage" className={css.pointerEvents}>
              {currentListing?.attributes?.title}
            </NamedLink>
          </div>
        </div>
      </div>
      <div className={css.bannerSection}>
        <div className={css.userDetails}>
          <div className={css.userCard}>
            <AvatarLarge
              className={css.avatar}
              user={currentAuthor}
              isDesigner={isDesigner}
              pathParams={pathParams}
            />
            <div className={css.userInfo}>
              <h3>{title}</h3>
              <p>{publicData?.marketType} </p>
              <div className={css.otherDetails}>
                <span>{renderPriceDetails(publicData)}</span>
                <span>
                  {' '}
                  {publicData?.budget ? capitalizeFirstLetter(publicData?.budget) : null}
                </span>
                <span>{publicData?.skillLevel}</span>
                <span>
                  {designerLevels.find(d => d.option === publicData?.designerLevel)?.label}
                </span>
              </div>
            </div>
          </div>
          {!isOwnListing &&
            (fetchTransactionsInProgress ? (
              <IconSpinner />
            ) : (
              <div className={css.applySec}>
                <PrimaryButton
                  type="button"
                  onClick={() => handleAuthOrRedirect()}
                  disabled={projectUnderContract}
                >
                  <FormattedMessage
                    id={projectUnderContract ? 'ListingPage.underContract' : 'ListingPage.applyNow'}
                  />{' '}
                  <Icons name="arrowUpRight" />
                </PrimaryButton>
              </div>
            ))}
        </div>
      </div>
      <div className={css.mainContent}>
        <div className={css.jobDetails}>
          <div className={css.jobBlock}>
            <div className={css.jobIcon}>
              <Icons name="calendarCheck" />
            </div>
            <div className={css.jobInfo}>
              <h2>
                <FormattedMessage id="ListingPage.datePostedHeading" />
              </h2>
              <p>{postedDaysAgo}</p>
            </div>
          </div>
          <div className={css.jobBlock}>
            <div className={css.jobIcon}>
              <Icons name="location" />
            </div>
            <div className={css.jobInfo}>
              <h2>
                <FormattedMessage id="ListingPage.postedByHeading" />
              </h2>
              <p>{getAuthorName(currentListing)}</p>
            </div>
          </div>
          {/* <div className={css.jobBlock}>
            <div className={css.jobIcon}>
              <Icons name="clock" />
            </div>
            <div className={css.jobInfo}>
              <h2>
                <FormattedMessage id="ListingPage.hoursHeading" />
              </h2>
              <p>
                <FormattedMessage id="ListingPage.hoursDescription" />
              </p>
            </div>
          </div> */}
          <div className={css.jobBlock}>
            <div className={css.jobIcon}>
              <Icons name="money" />
            </div>
            <div className={css.jobInfo}>
              <h2>
                <FormattedMessage id="ListingPage.budgetHeading" />
              </h2>
              <p>{renderPriceDetails(publicData)}</p>
            </div>
          </div>
        </div>
        <div className={css.otherDetails}>
          <h2>
            <FormattedMessage id="ListingPage.descriptionHeading" />
          </h2>
          <p>{description}</p>
        </div>
        <div className={css.otherDetails}>
          <h2>
            <FormattedMessage id="ListingPage.keyResponsibilitesHeading" />
          </h2>
          {isArrayLength(keyResponsibilities) ? (
            keyResponsibilities?.map((responsibility, index) => (
              <ul key={index}>
                <li>
                  <Icons name="check" /> {responsibility}
                </li>
              </ul>
            ))
          ) : (
            <p>
              <FormattedMessage id="ListingPage.noDataAvailable" />
            </p>
          )}
        </div>
        <div className={css.otherDetails}>
          <h2>
            <FormattedMessage id="ListingPage.workAndExperienceHeading" />
          </h2>
          {isArrayLength(workExperience) ? (
            workExperience?.map((experience, index) => (
              <ul key={index} className={css.bullets}>
                <li>{experience}</li>
              </ul>
            ))
          ) : (
            <p>
              <FormattedMessage id="ListingPage.noDataAvailable" />
            </p>
          )}

          {!isOwnListing &&
            (fetchTransactionsInProgress ? (
              <IconSpinner />
            ) : (
              <div className={css.applySec}>
                <PrimaryButton
                  type="button"
                  onClick={handleAuthOrRedirect}
                  disabled={projectUnderContract}
                >
                  <FormattedMessage
                    id={projectUnderContract ? 'ListingPage.underContract' : 'ListingPage.applyNow'}
                  />{' '}
                  <Icons name="arrowUpRight" />
                </PrimaryButton>
              </div>
            ))}
        </div>
        {isArrayLength(filteredJobListings) ? (
          <div className={css.relatedJobs}>
            <h2>
              <FormattedMessage id="ListingPage.relatedJobsHeading" />
            </h2>
            {filteredJobListings?.map(listing => {
              const listingAuthor = listing?.author;
              const { listingTitle = '', description, createdAt } = getListingDetails(listing);
              const listingBudgetType = getBudgetType(listing);
              const jobCategory = getJobCategory(listing);
              const designerLevel = getDesignerLevel(listing);
              return (
                <NamedLink
                  name="ListingPage"
                  params={{
                    id: listing?.id?.uuid,
                    slug: createSlug(listingTitle),
                  }}
                  className={css.userDetails}
                  key={listing?.id?.uuid}
                >
                  <div className={css.userCard}>
                    <AvatarMedium
                      className={css.avatar}
                      user={listingAuthor}
                      disableProfileLink={true}
                    />
                    <div className={css.userInfo}>
                      <h3>{listingTitle}</h3>
                      <p>{description}</p>
                      <div className={css.userCardDetails}>
                        <span>
                          {renderPriceDetails(listing?.attributes?.publicData)}{' '}
                          {capitalizeFirstLetter(listingBudgetType)}
                        </span>
                        <span>{getPostedDaysAgo(createdAt)}</span>
                        <span>{capitalizeFirstLetter(designerLevel)}</span>
                        <span>{jobCategory}</span>
                      </div>
                    </div>
                  </div>
                </NamedLink>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RenderJobInfo;
